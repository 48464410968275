<template>
  <div class="col-12" v-if="client">
    <Header />
    <div class="infos">
      <b-card>
        <div class="p-2 d-flex flex-column align-content-start">
          <h3>{{ this.client.config.login_text }}</h3>
          <h5>{{ this.client.config.login_subtext }}</h5>
        </div>

        <b-table
          class="mt-5"
          striped
          :items="client.users"
          :fields="fields"
        ></b-table>
      </b-card>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Header from "../Components/Header.vue";

export default {
  components: {
    Header,
  },
  data: () => ({}),
  methods: {
    ...mapActions("clients", ["ActionShow"]),
    async loadData() {
      await this.ActionShow();
    },
  },
  computed: {
    ...mapState("clients", ["client"]),
    fields() {
      return [
        { key: "id", label: "Id" },
        { key: "name", label: "Nome" },
        { key: "email", label: "Email" },
      ];
    },
  },
  async created() {
    await this.loadData();
  },
};
</script>

<style scoped>
</style>
